<template>
  <article id="sabian">

    <section id="top_bg">
    </section>

    <section id="top_bg2">
    </section>

    <section id="top">
      <h1>{{$t("Sabian.title")}}</h1>
    </section>

    <section id="painting" :paintings="paintings">
      <swiper
        @swiper="onSwiper"
        @slideChange="onSlideChange"
        :options="swiperOption"
        navigation
        :pagination="{ clickable: true }"
        effect="coverflow"
      >
        <div class="swiper-slide" v-for="(p, i) in paintings" :key="i" @click="link_to(i)">
          <swiper-slide :style="'background-image:url(/img/sabian/'+p.img+'); width:'+300*p.aspect+'px; margin:0 '+150*(1-p.aspect)+'px'"></swiper-slide>
        </div>
      </swiper>

      <div class="swiper-pagination"></div>
      
      <div id="description" :paintingDescription="paintingDescription" :lang="lang">
        {{paintingDescription.title[lang]}}<br>
        <small>{{paintingDescription.year}}, {{paintingDescription.media}}, {{paintingDescription.size}}</small>
      </div>
    </section>

    <section id="history">
      <h2>Profile</h2>
      <table>
        <tr v-for="(p, i) in profile" :key="i"><td>{{p.year}}</td><td>{{p.text[lang]}}</td></tr>
      </table>

      <h2>Exhibition</h2>
      <table>
        <tr v-for="(p, i) in exhibition" :key="i"><td>{{p.year}}</td><td>{{p.text[lang]}}</td></tr>
      </table>

      <h2>SNS for works</h2>
      <a href="https://www.instagram.com/rubyfmzk/" target="_blank">
        <button>Instagram</button>
      </a>
    </section>
  </article>
</template>

<script>
import 'swiper/swiper.scss'
import 'swiper/swiper-bundle.css'
import 'swiper/components/effect-coverflow/effect-coverflow.scss'
import 'swiper/components/pagination/pagination.scss'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import SwiperCore, {Pagination, EffectCoverflow} from 'swiper';
import artwork from '@/assets/yml/artwork.yml'

SwiperCore.use([Pagination, EffectCoverflow]);

export default {
  name: 'Sabian',

  components: {
    Swiper,
    SwiperSlide,
  },
  methods: {
    onSwiper() {
      const key = Object.keys(artwork.sabian)[0]
      this.paintingDescription = artwork.sabian[key]
    },
    onSlideChange(swiper) {
      const key = Object.keys(artwork.sabian)[swiper.activeIndex]
      this.paintingDescription = artwork.sabian[key]
    },
    link_to(i){
      this.$router.push({name: 'ShowImage', params:{series_name: 'sabian', artwork_id: i}})
    },
  },

  data () {
    return {
      lang: window.lang,
      swiperOption: {
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        coverflowEffect: {
          rotate: 25,
          stretch: -10,
          depth: 200,
          modifier: 2.3,
          slideShadows: true,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
      paintings: artwork.sabian,
      profile:[
        {year:1985, text:{ja:'北海道札幌市に生まれる', en:'Born in Sapporo, Hokkaido'}},
        {year:2002, text:{ja:'描きたいものがないことに気づき、絵画をやめる', en:'Stopped drawing because of having no idea what I wanted to draw'}},
        {year:2013, text:{ja:'心象を可視化するため絵画を再開する', en:'Restarted drawing to visualize the mental pictures'}},
        {year:2018, text:{ja:'西洋占星術を始め、サビアンシンボルに出会う', en:'Started with Astrology and met Sabian Symbols'}},
      ],
      exhibition:[
        {year:2017, text:{ja:'omnis「Evolving - イメージの解体 リアリティへの挑戦」(渋谷ヒカリエ 8, 渋谷)', en:'omnis "Evolving" (Shibuya Hikarie 8, Shibuya)'}},
        {year:2019, text:{ja:'omnis「Evolving - イメージの解体 リアリティへの挑戦」(渋谷ヒカリエ 8, 渋谷)', en:'omnis "Evolving" (Shibuya Hikarie 8, Shibuya)'}},
        {year:2019, text:{ja:'ShareArt「SHIBUYA STREET GALLERY」(渋谷駅周辺の壁面にて)', en:'ShareArt "SHIBUYA STREET GALLERY" (On the wall around Shibuya station)'}},
        {year:2019, text:{ja:'J-COLLABO「ANNUAL GROUP EXHIBITION 2019」(J-COLLABO, Brooklyn, New York)', en:' J-COLLABO「ANNUAL GROUP EXHIBITION 2019」(J-COLLABO, Brooklyn, New York)'}},
        {year:2020, text:{ja:'gallery hydrangea「あの日見た幻獣」(gallery hydrangea, 東京曳舟)', en:'gallery hydrangea "The mythical beast seen that day" (gallery hydrangea, Hikifune, Tokyo)'}},
        {year:2020, text:{ja:'GALLERY ART POINT「ART POINT Selection V」(GALLERY ART POINT, 銀座)', en:'GALLERY ART POINT「ART POINT Selection V」(GALLERY ART POINT, Ginza, Tokyo)'}},
        {year:2021, text:{ja:'GALLERY ART POINT「Dieux – 神々の世界」(GALLERY ART POINT, 銀座)', en:'GALLERY ART POINT「Dieux - World of Gods」(GALLERY ART POINT, Ginza, Tokyo)'}},
      ],
      paintingDescription: {},
    }
  },
  mounted() {
    const key = Object.keys(artwork.sabian)[0]
    this.paintingDescription = artwork.sabian[key]
  },
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#top_bg{
  padding: 0;
  position: fixed;
  background-image: url(/img/sabian/01_06_2000px.jpg);
  height: 100vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: 156%;
  background-position: 50% 100%;

  @media (min-width: 768px){
    background-size: cover;
    background-position: 50% 60%;
  }
}

#top_bg2{
    position: fixed;
    background-color: #77551687;
    height: 100vh;
    width: 100vw;
    mix-blend-mode: hard-light;
}

#top{
  background-position: 50% 0%;
  background-size: 130% auto;
  background-repeat: no-repeat;
  border-bottom: none;

  h1{
    color: #fff;
    font-size: 18px;
    padding-top: calc(50vh - 10px);
  }
}

@media (min-width: 768px){
  #top{
    h1{
      font-size: 24px;
    }
  }
}

#top_bg h1{
  position: fixed;
  left: 0;
    padding: 2vw;
    font-size: 20px;
}

@media (min-width: 768px){
  #top_bg{
  }
}

#top{
  height: 100vh;
  padding-top: 0;
  padding-bottom: 0;
}

#top2{
  color: #fff;
  padding: 30px 4vw 40vh;
  line-height: 2.6em;

  p{
    margin: auto;
    top: calc(50% - 25px);
    right: 0;
    left: 0;
  }
}

#painting{
  background: #000;
  position: relative;
  padding:  calc(50vh - 100px) 0;

  #description{
    margin: 50px 0 0 0;
  }

  .swiper-container {
    width: 100%;
    height: 302px;
  }

  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 300px;
  }
  .swiper-slide.swiper-slide-active > .swiper-slide:first-child{
    border: solid 1px #aaa;
  }
  .swiper-slide.square{
    width: 270px;
    height: 270px;
    margin: 15px;
  }

  .swiper-wrapper > .swiper-slide:not(.swiper-slide-active){
    opacity: 0.7;
  }

  .swiper-pagination{
    position: relative;
    margin: 10px auto 0;
  }
  @media (min-width: 768px){
    .swiper-pagination{
      display: none;
    }
  }
}

#history{
  background: #191c29;
  position: relative;
  padding: 150px 20px 50px;

  h2{
    font-size: 20px;
    margin: 0 0 30px;
  }

  table{
    margin: 0 auto 100px;
    max-width: 750px;
    width: 100%;
    text-align: left;
    tr{
      vertical-align: top;
      td:first-child{
        width: 70px;
      }
    }
  }
}


</style>